import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import logo from '../../assets/logoheader.png';
import style from './style.module.css';
import api from '../api/api';
import { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import { OddsContext } from '../context';
import { FaBars } from 'react-icons/fa'
import Sidebar from '../Sidebar'
import { Button } from '@mui/material';
import {auth,signOut} from '../../components/firebase/firebase'

export default function SearchAppBar() {
  const navigate = useNavigate();
  return (
    
    <div className={style.divgeral}>
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" >
        <Toolbar sx={{backgroundColor: '#0118E6'}}>
        </Toolbar>
      </AppBar>
    </Box>
    
    </div>
  );
}