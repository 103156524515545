import React from "react";
import { Formik, Form} from "formik";
import style from "./style.module.css";
import fundo from "../../assets/telaLogin.jpg";
import fundocell from "../../assets/telaLoginCell.jpg";
import logopng from "../../assets/logoheader.png";
import { Button } from "@mui/material";
import TextField from '@mui/material/TextField';
import {auth,sendPasswordResetEmail} from '../../components/firebase/firebase'
import { useNavigate } from "react-router-dom";

const Recuperar = () => {
  const navigate = useNavigate();
  return (
    <div className={style.main}>
      <img src={fundo} className={style.fundo}/>
      <img src={logopng} className={style.logopng}/>
      <div className={style.form}>
        <h1>Recuperar senha</h1>
        <Formik
      initialValues={{ email: '' }}
      validate={values => {
        const errors = {};
        if (!values.email) {
          errors.email = 'O e-mail é obrigatório';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Endereço de e-mail inválido';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          try {
            sendPasswordResetEmail(auth, values.email)
              .then(() => {
                alert("E-mail de recuperação de senha enviado com sucesso.")
              })
              .catch((error) => {
                alert(error)
              });
          } catch (error) {
            alert(error)
          }
          setSubmitting(false);
        }, 400);
      }}
    >
      {({ isSubmitting, values, handleChange, handleBlur, touched, errors }) => (
        <Form>
          <div className={style.textfield}>
          <TextField
            fullWidth
            id="email"
            name="email"
            label="E-mail"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
            margin="dense"
          />
          </div>
          
          <div className={style.divider}>
            <Button type="submit" disabled={isSubmitting} style={{ backgroundColor: '#0043b6' }} variant="contained">
              Enviar
            </Button>
            
          </div>

          <div className={style.conta}>
            <p onClick={() => navigate('/Login')} style={{ cursor: 'pointer', color: '#0043b6', textDecoration: 'underline' }}>
                Já tem uma conta?
            </p>
          </div>
          
        </Form>
      )}
    </Formik>
        
      </div>
    </div>
  );
};

export default Recuperar;