import {React, useState} from 'react';

import style from './style.module.css';
import iconFutebol from '../../assets/icon_futebol.png';
import iconTenis from '../../assets/icon_tenis.png';
import iconBasquete from '../../assets/icon_basquete.png';
import iconFormula from '../../assets/icon_formula.png';
import iconVolei from '../../assets/icon_volei.png';
import iconSeta from '../../assets/icon_seta.png';
import logo from '../../assets/LogoPreta.png';

const SportsList = () => {

  const sports = [

    'Basquete (EM BREVE)',

    'Vôlei (EM BREVE)',

    'Tênis (EM BREVE)',

    'Formula 1 (EM BREVE)',

  ];

  const subsports = [

    'Brasileirão Série A',

    'Brasileirão Série B',

    'Libertadores',

  ];

  const icons = [
  
      iconBasquete,

      iconVolei,

      iconTenis,
  
      iconFormula,
  
    ];


  const [showSubsports, setShowSubsports] = useState(false);

  return (
    <div className={style.main}>
      <img className={style.logolateral} src={logo}></img>
      <ul onClick={() => setShowSubsports(!showSubsports)}><img className={style.icon} src={iconFutebol}></img> Futebol <img className={style.iconSeta} src={iconSeta} style={{transform: showSubsports ? 'rotate(180deg)' : 'rotate(0deg)'}}></img></ul>
      {showSubsports && (
        <ul className="sports-list">
          {subsports.map((sport, index) => (
            <li key={index}>{sport}</li>
          ))}
        </ul>
      )}

      {sports.map((sport, index) => (
        <ul key={index}><img className={style.icon} src={icons[index]}></img> {sport}</ul>
      ))}
    </div>
  );

};


export default SportsList;