import React from 'react';
import Main from ".././main/index";
import Header from "../../components/Header/index";
import JivoChat from '../../components/jivocomp';
import { FaBars } from 'react-icons/fa'
import Sidebar from "../../components/Sidebar/index"
import { OddsProvider } from "../../components/context";
const Geral = () => {
    const [sidebar, setSidebar] = React.useState(false)
    const showSiderbar = () => setSidebar(!sidebar)
    return (
        <div>
            <JivoChat />
            <OddsProvider>
            <div style={{display: "none"}}>
            <FaBars onClick={showSiderbar} />
            {sidebar && <Sidebar active={setSidebar} />}
          </div>
            <Header />
            <Main />
            </OddsProvider>
        </div>
    );
};

export default Geral;