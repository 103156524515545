import React, { useEffect } from "react";
import api from '../../components/api/api';
import bet365 from "../../assets/bet365.png";
import betano from "../../assets/betano.png";
import betway from "../../assets/betway.png";
import rivalry from "../../assets/rivalry.png";
import sportingbet from "../../assets/sportingbet.png";
import vaidebet from "../../assets/vaidebet.png";
import pixbet from "../../assets/pixbet.png";
import x1bet from "../../assets/1xbet.png";
import casaDeApostas from "../../assets/image.png";
import betmais from "../../assets/betmais.png";
import esportivabet from "../../assets/esportivabet.png";
import brisabet from "../../assets/brisabet.png";
import style from "./style.module.css";
import Footer from "../../components/Footer/index";
import carrossel1 from "../../assets/carrossel1.png";
import carrossel2 from "../../assets/carrossel2.png";
import carrossel3 from "../../assets/carrossel3.png";
import CircularProgress from '@mui/material/CircularProgress';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/swiper-bundle.css';
import 'swiper/css/effect-fade';
import { useNavigate } from "react-router-dom";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade } from 'swiper/modules';
import Lateral from "../../components/Lateral/index";
import { OddsContext } from "../../components/context";
import ad from "../../assets/3.png";
import {auth, onAuthStateChanged } from '../../components/firebase/firebase'
function Clicada() {
    const navigate = useNavigate();
  useEffect(() => {
     
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log('Usuário logado')
      }
      else {
        navigate("/login")
      }
    });
  }, []);

    const handleWebSocket = () => {
        let ws;
    
        const connectWebSocket = () => {
          ws = new WebSocket('wss://googolbet.com.br/ws/',[],0);
    
          ws.onopen = () => {
            //console.log('WS Connected');
          };
    
          ws.onclose = (event) => {
            //console.log('WS Disconnected:', event.code, event.reason);
    
            if (event.code === 1006) {
              //console.log('Tentando reconectar...');
              setTimeout(connectWebSocket, 2000);
            }
          };
    
          ws.onerror = (err) => {
            //console.log("WS Error")
          };
    
          ws.onmessage = (e) => {
            //console.log(e)
            setTimeout(() => {
                getoddclicada()
            }, 10000)
          }
        }
        connectWebSocket();
      }
    const [slidesPerView, setSlidesPerView] = React.useState(2);
    const data = [
        { id: '1', image: carrossel3 },
        { id: '2', image: carrossel2 },
        { id: '3', image: carrossel1 },
    ]

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 768) {
                setSlidesPerView(1);
            } else {
                setSlidesPerView(2);
            }
        }

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const [odd, setOdd] = React.useState([]);
    const [nomeFora, setnomeFora] = React.useState("")
    const [nomeCasa, setnomeCasa] = React.useState("")
    const [imgfora, setimgfora] = React.useState("")
    const [imgcasa, setimgcasa] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(true);
    const [selectedOption, setSelectedOption] = React.useState("option1");
    const { atualizaodd, setAtualizaodd } = React.useContext(OddsContext);
    const getoddclicada = async () => {
        setnomeFora(localStorage.getItem("nomefora"))
        setnomeCasa(localStorage.getItem("nomecasa"))
        setimgfora(localStorage.getItem("logofora"))
        setimgcasa(localStorage.getItem("logocasa"))
        try {
            setIsLoading(true);
            const response = await api.get(`/bets/${localStorage.getItem("id")}`, {})
                .then((response) => {
                    setOdd(response.data.data)
                    setIsLoading(false);
                })
                .catch(function (error) {
                    window.alert('deu erro.');
                });
            return response;
        } catch (error) {
            throw error;
        }
    }
    useEffect(() => {
        handleWebSocket();
      }, []);
    useEffect(() => {
        getoddclicada()
    }, [atualizaodd]);

    return (
        <div>
         <div className={style.divcarrossel}>
                <div className={style.carrossel}>
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade]}
                        slidesPerView={1}
                        pagination={{ clickable: true }}
                        loop={true}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }}
                    >
                        {data.map((item) => (
                            <SwiperSlide key={item.id}>
                                <img
                                    src={item.image}
                                    alt="Slider"
                                    className={style.sliderItem}
                                />
                            </SwiperSlide>
                        ))}

                        <div className="swiper-button-prev"></div>
                        <div className="swiper-button-next"></div>
                    </Swiper>
                </div>
            </div>

            <div className={style.geral}>
                <Lateral />
                <div className={style.main}>
                {isLoading ? (
                    <div className="loading-bar">
                        <CircularProgress />
                    </div>
                ) : (
                    <div></div>
                )}
                    <p>Classificar por: </p>
                    <select className={style.dropdownrow} value={selectedOption} onChange={handleOptionChange}>

                        <option value="option1">Resultado 1X2</option>
                        <option value="option2">Ambas as equipes marcam</option>
                        <option value="option3">Total de gols</option>
                    </select>

                    <div className={style.headerBet}>
                        <div className={style.divHeaderBet}>
                            {/* <p className={style.horario}>{odd.data} | {odd.hora}</p> */}

                            <img src={imgcasa} alt="Team Logo" className={style.teamLogo} />
                            <p className={style.nomeCasa}>{nomeCasa}</p>
                            <p>vs</p>
                            <p className={style.nomeFora}>{nomeFora}</p>
                            <img src={imgfora} alt="Team Logo" className={style.teamLogo} />
                        </div>
                    </div>


                    <div className={style.bets}>
                        {Array.isArray(odd) && odd.map((odd, index) => (
                            <div key={index} className={style.divBets}>
                                <div className={style.objbet}>

                                    {odd.bethouse.nome === 'bet365' && (
                                        <img src={bet365} alt="Logo" className={style.logobet}></img>
                                    )}
                                    {odd.bethouse.nome === 'betano' && (
                                        <img src={betano} alt="Logo" className={style.logobet}></img>
                                    )}
                                    {odd.bethouse.nome === 'betway' && (
                                        <img src={betway} alt="Logo" className={style.logobet}></img>
                                    )}
                                    {odd.bethouse.nome === 'rivalry' && (
                                        <img src={rivalry} alt="Logo" className={style.logobet}></img>
                                    )}
                                    {odd.bethouse.nome === 'sportingbet' && (
                                        <img src={sportingbet} alt="Logo" className={style.logobet}></img>
                                    )}
                                    {odd.bethouse.nome === 'vaidebet' && (
                                        <img src={vaidebet} alt="Logo" className={style.logobet}></img>
                                    )}
                                    {odd.bethouse.nome === 'pixbet' && (
                                        <img src={pixbet} alt="Logo" className={style.logobet}></img>
                                    )}
                                    {odd.bethouse.nome === 'x1bet' && (
                                        <img src={x1bet} alt="Logo" className={style.logobet}></img>
                                    )}
                                    {odd.bethouse.nome === 'casadeapostas' && (
                                        <img src={casaDeApostas} alt="Logo" className={style.logobet}></img>
                                    )}
                                    {odd.bethouse.nome === 'betmais' && (
                                        <img src={betmais} alt="Logo" className={style.logobet}></img>
                                    )}
                                    {odd.bethouse.nome === 'brisabet' && (
                                        <img src={brisabet} alt="Logo" className={style.logobet}></img>
                                    )}
                                    {odd.bethouse.nome === 'esportiva' && (
                                        <img src={esportivabet} alt="Logo" className={style.logobet}></img>
                                    )}

                                    <div className={style.divbutton}>
                                        {selectedOption === "option1" ? (
                                            <div className={style.divbutton}>

                                                <div className={style.buttonclicada}>
                                                    <p>Casa</p>
                                                    {odd.casa_odd && (
                                                        <a href={`${odd.bethouse.bethouse_url}`} target="_blank" rel="noreferrer">
                                                            <button className={style.button1}>{odd.casa_odd}</button>
                                                        </a>
                                                    )}
                                                </div>

                                                <div className={style.buttonclicada}>
                                                    <p>Empate</p>
                                                    {odd.empate_odd && (
                                                        <a href={`${odd.bethouse.bethouse_url}`} target="_blank" rel="noreferrer">
                                                            <button>{odd.empate_odd}</button>
                                                        </a>
                                                    )}
                                                </div>

                                                <div className={style.buttonclicada}>
                                                    <p>Fora</p>
                                                    {odd.fora_odd && (
                                                        <a href={`${odd.bethouse.bethouse_url}`} target="_blank" rel="noreferrer">
                                                            <button>{odd.fora_odd}</button>
                                                        </a>
                                                    )}
                                                </div>
                                            </div>
                                        ) : selectedOption === "option2" ? (
                                            <div className={style.divbutton}>

                                                <div className={style.buttonclicada}>
                                                    <p>Sim</p>
                                                    {odd.ambos_marcam_sim_odd && (
                                                        <a href={`${odd.bethouse.bethouse_url}`} target="_blank" rel="noreferrer">
                                                            <button>{odd.ambos_marcam_sim_odd}</button>
                                                        </a>
                                                    )}
                                                </div>

                                                <div className={style.buttonclicada}>
                                                    <p>Não</p>
                                                    {odd.ambos_marcam_nao_odd && (
                                                        <a href={`${odd.bethouse.bethouse_url}`} target="_blank" rel="noreferrer">
                                                            <button>{odd.ambos_marcam_nao_odd}</button>
                                                        </a>
                                                    )}
                                                </div>
                                            </div>
                                        ) : selectedOption === "option3" ? (
                                            <div className={style.divbutton}>

                                                <div className={style.buttonclicada}>
                                                    <p>1.5+</p>
                                                    {odd.gol_mais15_odd && (
                                                        <a href={`${odd.bethouse.bethouse_url}`} target="_blank" rel="noreferrer">
                                                            <button>{odd.gol_mais15_odd}</button>
                                                        </a>
                                                    )}
                                                </div>

                                                <div className={style.buttonclicada}>
                                                    <p>1.5-</p>
                                                    {odd.gol_menos15_odd && (
                                                        <a href={`${odd.bethouse.bethouse_url}`} target="_blank" rel="noreferrer">
                                                            <button>{odd.gol_menos15_odd}</button>
                                                        </a>
                                                    )}
                                                </div>

                                            </div>
                                        ) : null}

                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
                
            <div className={style.ad}>
                <img src={ad}/>
            </div>

            </div>
        

            <Footer />
        </div>
    );
}

export default Clicada;
