import React, { createContext, useState} from 'react';

// Create the context
export const OddsContext = createContext();

// Context provider component
export const OddsProvider = ({ children }) => {
    const [atualizaodd, setAtualizaodd] = useState([]);

    return (
        <OddsContext.Provider value={{ atualizaodd, setAtualizaodd }}>
            {children}
        </OddsContext.Provider>
    );
};
