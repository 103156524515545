import React, { useEffect } from "react";
import style from "./style.module.css";
import Footer from "../../components/Footer/index";
import carrossel1 from "../../assets/carrossel1.png";
import carrossel2 from "../../assets/carrossel2.png";
import carrossel3 from "../../assets/carrossel3.png";
import logo from "../../assets/logofooter.png";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/swiper-bundle.css";
import "swiper/css/effect-fade";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import ad from "../../assets/3.png";
import { OddsContext } from "../../components/context";
import {
  auth,
  onAuthStateChanged,
  analytics,
} from "../../components/firebase/firebase";
import { logEvent } from "firebase/analytics";

export default function Main() {
  function HandleClick(link) {
    logEvent(analytics, link);
  }

  useEffect(() => {});

  const [slidesPerView, setSlidesPerView] = React.useState(2);
  const data = [
    { id: "1", image: carrossel3 },
    { id: "2", image: carrossel2 },
    { id: "3", image: carrossel1 },
  ];

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        setSlidesPerView(1);
      } else {
        setSlidesPerView(2);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const navigate = useNavigate();

  return (
    <div style={{ borderRadius: "15px" }}>
      <div className={style.divcarrossel}>
        <div className={style.carrossel}>
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade]}
            slidesPerView={1}
            pagination={{ clickable: true }}
            loop={true}
            style={{ width: "100%", height: "100%" }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
          >
            {data.map((item) => (
              <SwiperSlide key={item.id}>
                <img
                  src={item.image}
                  alt="Slider"
                  className={style.sliderItem}
                />
              </SwiperSlide>
            ))}

            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
          </Swiper>
        </div>
      </div>
      <div className={style.fantasy}>
        <img src={logo} alt="Logo" />
        <h1>A mais nova plataforma de Fantasy Gaming</h1>
        <h1>Aguardem!</h1>
      </div>
      <Footer />
    </div>
  );
}
