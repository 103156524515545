import React from 'react';
import Clicada from ".././clicada/index";
import Header from "../../components/Header/index";
import { OddsProvider } from "../../components/context";
const Geral = () => {
    // Lógica para gerar o conteúdo principal (main) aqui

    return (
        <div>
            <OddsProvider>
            <Header />
            <Clicada />
            </OddsProvider>
        </div>
    );
};

export default Geral;