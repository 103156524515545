import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Login from "./pages/login/index";
import Recuperar from "./pages/recuperar/index";
import Cadastro from "./pages/cadastro/index";
import Geral from "./pages/geral/index";
import Clicada from "./pages/clicadageral/index";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Geral/>,
  },
  {
    path: "/bet",
    element: <Clicada />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/cadastro",
    element: <Cadastro />,
  },
  {
    path: "/recuperar",
    element: <Recuperar />,
  }                              
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);