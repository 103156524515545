import React from 'react';


class JivoChat extends React.Component {

  componentDidMount() {

      const jivoScript = document.createElement('script');
    
      jivoScript.async = true;
    
      jivoScript.src = '//code.jivosite.com/widget/Xu75OIlvxL';
    
      document.getElementById('jivo-iframe').appendChild(jivoScript);
    

  }


  render() {

    return <div id="jivo-iframe" />;

  }

}


export default JivoChat;