import React from "react";
import { Formik, Form} from "formik";
import style from "./style.module.css";
import fundo from "../../assets/telaCadastro.jpg";
import logopng from "../../assets/logoheader.png";
import fundocell from "../../assets/telaCadastroCell.jpg";
import avatar from "../../assets/avatar.png";
import { Button } from "@mui/material";
import TextField from '@mui/material/TextField';
import { auth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, signOut, googleProvider } from '../../components/firebase/firebase';
import { useNavigate } from "react-router-dom";
import GoogleIcon from '@mui/icons-material/Google';
import axios from "axios";
import api from '../../components/api/api'

const Cadastro = () => {
  const navigate = useNavigate();
  const salvarinfo = async (uid, email, nome) => {
    console.log(uid, email, nome)
    try {
      const data = await api.post('/firebase/addUser', {
        uid: uid,
        email: email,
        nome: nome
      })
      .then(() => {
        console.log('Usuário cadastrado com sucesso');
      }
      )
    } catch (error) {
      // Handle the error here
    }
  }
  const loginwithgoogle = async (auth, googleProvider) => {
    try {
      await signInWithPopup(auth, googleProvider)
        .then((user) => {
          salvarinfo(user.user.uid, user.user.email, user.user.displayName)
          navigate('/');
        })
        .catch((error) => {
        });
    } catch (error) {
    }
  }

  return (
    <div className={style.main}>
      <img src={fundo} className={style.fundo} />
      <img src={logopng} className={style.fundopng} />
      <div className={style.form}>
        <div className={style.divAvatar}>
          <img src={avatar} className={style.avatar}/>
          <h1>Cadastro</h1>
        </div>
        <Formik
          initialValues={{ email: '', password: '' }}
          validate={values => {
            const errors = {};
            if (!values.email) {
              errors.email = 'O e-mail é obrigatório';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Endereço de e-mail inválido';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              createUserWithEmailAndPassword(auth, values.email, values.password)
                .then((user) => {
                  salvarinfo(user.user.uid, values.email, values.email.substring(0, 5))
                  signInWithEmailAndPassword(auth, values.email, values.password)
                    .then(() => navigate('/'))
                    .catch((error) => {
                    });
                })
                .catch((error) => {
                  alert('Email ja cadastrado ou senha muito fraca');
                });
              setSubmitting(false);
            }, 400);
          }}
        >
          {({ isSubmitting, values, handleChange, handleBlur, touched, errors }) => (
            <Form>
              <div className={style.textfield}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="E-mail"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                margin="dense"
              />
              <TextField
                fullWidth
                id="password"
                name="password"
                label="Senha"
                type="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                margin="dense"
              />
              </div>
              <div className={style.divider}>
                <Button type="submit" disabled={isSubmitting} style={{ backgroundColor: '#0043b6', color: '#fff' }} variant="contained">
                  Enviar
                </Button>

                <GoogleIcon
                  onClick={() => loginwithgoogle(auth, googleProvider)}
                  style={{ backgroundColor: '#0043b6', borderRadius: '50%', padding: '10px', cursor: 'pointer', color: 'white' }}
                  fontSize="medium"
                />
              </div>

              <div className={style.conta}>
                <p onClick={() => navigate('/login')} style={{ cursor: 'pointer', color: '#0043b6', textDecoration: 'underline' }}>
                  Já tem uma conta?
                </p>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Cadastro;
