// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/Gotham-Light.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_logo__UIoOr{
    width: 25vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8px;
}

@media (max-width: 800px) {
    .style_logo__UIoOr{
        width: 10vh;
        margin-top: 28px;
    }
    
}

i{
    width: 50px;
    height: 50px;
    margin-left: 10px;
    margin-right: 10px;
    float: right;
    color: white;
}

.style_footcontainer__WWJ4E{
    background-color: black;
    color: white;
    gap: 20px;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.style_iconscontainer__ElyaM{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-top: 25px;
}

@font-face {
    font-family: gotham-light;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.style_textcontainer__NzVWt{
    align-self: left;
    font-family: gotham-light;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/style.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI;QACI,WAAW;QACX,gBAAgB;IACpB;;AAEJ;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,uBAAuB;IACvB,YAAY;IACZ,SAAS;IACT,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,QAAQ;IACR,aAAa;IACb,kCAAkC;AACtC;;AAEA;IACI,aAAa;IACb,qBAAqB;IACrB,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,4CAAyC;AAC7C;;AAEA;IACI,gBAAgB;IAChB,yBAAyB;AAC7B","sourcesContent":[".logo{\n    width: 25vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-left: auto;\n    margin-right: auto;\n    margin-top: 8px;\n}\n\n@media (max-width: 800px) {\n    .logo{\n        width: 10vh;\n        margin-top: 28px;\n    }\n    \n}\n\ni{\n    width: 50px;\n    height: 50px;\n    margin-left: 10px;\n    margin-right: 10px;\n    float: right;\n    color: white;\n}\n\n.footcontainer{\n    background-color: black;\n    color: white;\n    gap: 20px;\n    position: relative;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr;\n}\n\n.iconscontainer{\n    display: flex;\n    align-items: flex-end;\n    justify-content: flex-end;\n    padding-top: 25px;\n}\n\n@font-face {\n    font-family: gotham-light;\n    src: url('../../assets/Gotham-Light.ttf');\n}\n\n.textcontainer{\n    align-self: left;\n    font-family: gotham-light;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `style_logo__UIoOr`,
	"footcontainer": `style_footcontainer__WWJ4E`,
	"iconscontainer": `style_iconscontainer__ElyaM`,
	"textcontainer": `style_textcontainer__NzVWt`
};
export default ___CSS_LOADER_EXPORT___;
