import React from 'react';
import logo from "../../assets/logofooter.png";
import style from "./style.module.css";
import "bootstrap-icons/font/bootstrap-icons.css";

const Footer = () => {
    return (
        <footer>
        <div className={style.footcontainer}>
            <div className={style.textcontainer}>
            
            </div>

            <div className={style.logocontainer}>
                <img src={logo} alt="Logo" className={style.logo} />
            </div>

            <div className={style.iconscontainer}>
            <a href="https://x.com">
                <i className="bi bi-twitter"></i>
            </a>
            <a href="https://facebook.com">
                <i className="bi bi-facebook"></i>
            </a>
            <a href="https://instagram.com">
                <i className="bi bi-instagram"></i>
            </a>
            </div>
        </div>
        </footer>
    );
}

export default Footer;